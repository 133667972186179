<template>
  <v-card>
    <v-system-bar>Kurs-Flipper</v-system-bar>
    <v-card-text>
      <Flipper :value="course.students" :name="`course_flipper_${course.id}`" />
    </v-card-text>
  </v-card>
</template>

<script>
import { defineComponent } from "vue";
import Flipper from "@/components/Flipper";
export default defineComponent({
  name: "CourseFlipper",
  components: { Flipper },
  props: ["course"],
  data() {
    return {};
  },
  methods: {},
  created() {},
});
</script>
